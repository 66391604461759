#ResourceListContainer {
  max-height: 200px;
  position: relative;
  overflow: overlay;
  border: 1px solid #e7e7e7;
}

.payment_gateway_titles{
  word-wrap: break-word;
}

.productSeletionBarContainer {
  max-height: 120px;
  position: relative;
  overflow: overlay;
  border: 1px solid #e7e7e7;
  padding-bottom: 10px;
  padding-left: 5px;
}

#plusMinusButtonDiv {
  margin-top: 10px;
}

#minusDiv {
  margin-left: 5px;
}

.productSeletionBarSpanMain {
  padding: 5px;
}

.productSeletionBarSpan1 {
  margin: 10px 0 0 0;
}

.submitBtnText {
  float: left;
  padding: 5px;
}
/* .Polaris-ResourceList__CheckableButtonWrapper{
    margin-top: -36px;
} */
.Polaris-ResourceList__HeaderWrapper.Polaris-ResourceList__HeaderWrapper--hasSelect {
  display: none;
}
.thead-th {
  font-size: 16px;
}
.table-thead {
  margin-top: 50px;
  text-align: left;
}
.custom-margin-bottom {
  margin-bottom: 10px;
}

/* ----------overlay--------------- */
.list-page-overlay {
  background: transparent;
  opacity: 0.6;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 999;
  pointer-events: none;
}
.list-page-overlay-visible {
  display: block;
  opacity: 1;
  height: 100%;
  width: 100%;
  z-index: 999999;
  pointer-events: visible;
}
.loading-spinner {
  margin-top: 23%;
  margin-left: 25%;
  position: fixed;
}
.loading-modal-spinner {
  position: fixed;
  margin-top: 1%;
  margin-left: 20%;
}
.Polaris-Button__Text a {
  color: white;
  text-decoration: none;
}
.seller__list__table__tableHeading,
.theme__list__table__tableHeading,
.asset__list__table__tableHeading {
  font-weight: bold;
}
.seller__list__table__shopURL {
  width: 40%;
  word-break: break-word;
}
.seller__list__table__shopName {
  width: 18%;
  word-break: break-word;
}
.seller__list__table__installDate {
  width: 20%;
  word-break: break-word;
}
.seller__list__table__installStatus {
  width: 12%;
  word-break: break-word;
}
.seller__list__table__action {
  width: 10%;
  text-align: center;
}

.theme__list__table__createdAt {
  width: 20%;
  word-break: break-word;
  padding: 10px;
}
.theme__list__table__name {
  width: 20%;
  word-break: break-word;
}
.theme__list__table__role {
  width: 15%;
  word-break: break-word;
}
.theme__list__table__updatedAt {
  width: 18%;
  word-break: break-word;
}
.theme__list__table__themeId {
  width: 17%;
  word-break: break-word;
}
.theme__list__table__action {
  width: 10%;
  text-align: center;
}

.asset__list__table__key {
  width: 30%;
  word-break: break-word;
  padding: 10px;
}
.asset__list__table__contentType {
  width: 25%;
  word-break: break-word;
}
.asset__list__table__createdAt {
  width: 17%;
  word-break: break-word;
}
.asset__list__table__updatedAt {
  width: 18%;
  word-break: break-word;
}
.asset__list__table__action {
  width: 10%;
  text-align: center;
}

.key-selection-div{
  padding: 0 0 20px 30px;
}
.key-display-text{
  float: left;
  padding-right: 20px;
}
.key-select-div{
  float: left;
  min-height: 20px;
  width: 30%;
  margin-right: 20px;
}
.key-select{
  height: 35px;
  width: 100%;
  font-size: 18px;
  padding-left: 10px;
}
#PolarisTextField1{
  font-size: 18px;
}
.CodeMirror{
  height: 800px !important;
}