.gateways__section{
    min-width: 250px;
    max-width: 250px;
}
.gateways__section .Polaris-Card__Section:first-child{
    text-align: center;
    display: inline;
}

.container__body{
    padding-left: 20%;
}
.layout__navbar{ /* logout button bottom fix */
    position: fixed;
    background: transparent;
    top: 90%;
    display: inline-flex;
    width: 100%;
    justify-content: center;
}

.date_range_buttons{
    cursor: pointer;
}
.spinner__div{
    padding-top: 10px;
}
.button__date__text{
    padding-top: 2px;
    min-width: 150px;
}
.select__gateway__text{
    min-width: 250px;
}
.date__range__button__width{
    min-width: 250px;
}

.export__button{
    text-decoration: none;
}